// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-architect-learning-mdx": () => import("./../../../src/pages/architect-learning.mdx" /* webpackChunkName: "component---src-pages-architect-learning-mdx" */),
  "component---src-pages-architect-team-discussions-part-1-mdx": () => import("./../../../src/pages/architect-team-discussions-part-1.mdx" /* webpackChunkName: "component---src-pages-architect-team-discussions-part-1-mdx" */),
  "component---src-pages-architect-team-discussions-part-2-mdx": () => import("./../../../src/pages/architect-team-discussions-part-2.mdx" /* webpackChunkName: "component---src-pages-architect-team-discussions-part-2-mdx" */),
  "component---src-pages-core-of-devops-processes-in-org-mdx": () => import("./../../../src/pages/core-of-devops-processes-in-org.mdx" /* webpackChunkName: "component---src-pages-core-of-devops-processes-in-org-mdx" */),
  "component---src-pages-create-custom-gpts-mdx": () => import("./../../../src/pages/create-custom-gpts.mdx" /* webpackChunkName: "component---src-pages-create-custom-gpts-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infrastructure-team-approaches-mdx": () => import("./../../../src/pages/infrastructure-team-approaches.mdx" /* webpackChunkName: "component---src-pages-infrastructure-team-approaches-mdx" */),
  "component---src-pages-mobx-async-data-mdx": () => import("./../../../src/pages/mobx-async-data.mdx" /* webpackChunkName: "component---src-pages-mobx-async-data-mdx" */),
  "component---src-pages-tests-is-speed-mdx": () => import("./../../../src/pages/tests-is-speed.mdx" /* webpackChunkName: "component---src-pages-tests-is-speed-mdx" */),
  "component---src-pages-utilizing-ll-ms-to-accomplish-dev-tasks-on-example-mdx": () => import("./../../../src/pages/utilizing-LLMs-to-accomplish-dev-tasks-on-example.mdx" /* webpackChunkName: "component---src-pages-utilizing-ll-ms-to-accomplish-dev-tasks-on-example-mdx" */)
}

